import React, { useEffect } from "react"
import NaturalThanks from "../../components/NaturalOasis/NaturalOasisThanks"
import SEO from "../../components/seo"
import "../../styles/layout.module.css"

const Thanks = () => {
    useEffect(() => {
        window.dataLayer.push({
            brand: "Corona",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Ingredientes Naturales Thanks",
            pageType: "Conversion Success",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.cervezacorona.com.ar/ingredientesnaturales/thanks",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        })
    }, [])

    return (
        <div>
            <SEO title="Gracias" />
            <NaturalThanks />
        </div>
    )
}

export default Thanks
