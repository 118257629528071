import React from "react"
import styles from "./buttonmenu.module.scss"

const ButtonMenu = props => {
  const handleClickMenu = () => {
    props.toggleMenu()
  }

  return (
    <>
      <div className={styles.hamburgerButtonPosition} styles={{ zIndex: "10" }}>
        <button className="navbar-toggler" onClick={handleClickMenu}>
          <span
            className={
              props.activeMenu ? styles.hamburgertop : styles.hamburgertoptoggle
            }
          ></span>
          <span
            className={
              props.activeMenu
                ? styles.hamburgercenter
                : styles.hamburgercentertoggle
            }
          ></span>
          <span
            className={
              props.activeMenu
                ? styles.hamburgerbottom
                : styles.hamburgerbottomtoggle
            }
          ></span>
        </button>
      </div>
    </>
  )
}

export default ButtonMenu
