import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styles from './menu.module.scss';
import facebook from '../../images/Facebook.png';
import insta from '../../images/Instagram.png';

const Menu = (props) => {

  const [toggleMenu, setToggleMenu] = useState();

  useEffect(() => {

    if (props.activeMenu) {
      setToggleMenu(false);
      enableScroll();
       
    } else {
      setToggleMenu(true);
      disableScroll();
    }
  });


  const disableScroll = () => {
    window.scrollTo(0, 0)
    const x = window.scrollX;
    const y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y) };
  }

  const enableScroll = () => {
    window.onscroll = null;
  }

  return (
    <>
      <div className={props.activeMenu ? styles.hiddenMenu : styles.menu}>
        <div className={props.activeMenu ? styles.hiddenContainerMenu : styles.containerMenu}>
          <div>
          </div>
          <div className={styles.w55}>
            <div className={styles.hamburgerMenuLinks}>
              {/* <Link to="/viajesconconexion" ><div className={styles.fontMenu}>HOME</div></Link> */}
            </div>
            <div className={styles.hamburgerMenuLinks}>
            {/* <a href="/viajesconconexion#ingresa"> <div className={styles.fontMenu}><span className={styles.yellowText}>PARTICIPÁ </span>POR UN VIAJE</div> </a> */}
            </div>
            <div className={styles.hamburgerMenuLinks}>
            {/* <Link to="/viajesconconexion/experiencias" ><div className={styles.fontMenu}><span className={styles.yellowText}>EXPLORÁ </span>Los DESTINOS</div> </Link> */}
            </div>
            <div className={styles.hamburgerMenuLinks}>
              <Link to="https://www.rappi.com.ar/tiendas/tipo/bebidas" target="_blank"><div className={styles.fontMenu}><span className={styles.yellowText}>COMPRÁ </span>TU SIX PACK</div> </Link>
            </div>
          </div>
          <div className={styles.fontFooterMenu}>
         <div className="w-100">
         <Link to='https://www.facebook.com/cervezacorona'><img src={facebook} className={styles.redes} alt=""/></Link>
         <Link to='https://instagram.com/corona_argentina?igshid=oxhoy01vemn0'><img src={insta} alt="" className={styles.redes}/></Link>
          </div>
            <div className={styles.fontTermsAndConditions}>
              <span> <a href={'/TyCCervezaCorona.pdf'} download
                target="blank"> TÉRMINOS DE USO </a> | <a href={'/ppdp.pdf'} download
                target="blank"> POLÍTICAS DE PRIVACIDAD </a> | <a href={'/BBCC.pdf'} download
                                                                  target="blank"> BASES Y CONDICIONES </a> </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu;
